/* https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;1,600&display=swap */

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(./Montserrat-SemiBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./Montserrat-Bold.ttf) format("truetype");
}