/*
  Bug mit neuer bootstrap-react Version!
  https://github.com/react-bootstrap/react-bootstrap/pull/5671
*/

/* Layout */
body {
  margin: 0;
  color:#0b0334;
}
#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#footerPrev {
  text-align: center;
  margin-bottom: 5px;
  margin-left: 10px;
}
#footerNext {
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 8px;
  margin-right: 10px;
}
@media (max-width: 992px) {
  #footerPrev {
    text-align: center;
    margin-left: 10px;
    margin-bottom: 0px;
  }
  #footerNext {
    text-align: center;
    margin-bottom: 5px;
    padding-right: 20px;
    margin-left: -25px;
  }
}

#content-wrapper {
  width:100%;
  margin:auto;
  margin-top: 5%;
  margin-bottom: 50px;
}
#rowStartseite {
  width:80%;
  margin:auto;
}
#rowSelbsteinschaetzung{
  width:80%;
  margin:auto;
}

/* Typo Besonderheiten */

#headline {
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 10%;
}
#barriereIntro {
  font-weight:100;
  font-size: 0.8em;
}

/* Besonderheiten nach Bildschirm-Auflösung */

@media (max-width: 768px) {
  #rowSelbsteinschaetzung{
    width:100%;
    margin:auto;
  }
  footer {
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  @supports (padding: max(0px)) {
    footer {
      padding-bottom: max(5px, env(safe-area-inset-bottom));
    }
  }
  #content-wrapper {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  /* Striche bei Menü */
  nav:before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 60px;
    border: 2px solid #0b0334;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
  nav:after {
    content: " ";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0;
    width: 60px;
    border: 2px solid #0b0334;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}

/* Allgemein */
:root {
  --personaA-color: #edd9dc;
  --personaB-color: #d2e0e8;
  --personaC-color: #e4ddd2;
  --personaD-color: #d3e3d3;
  --personaE-color: #e3daeb;
}

.navText {
  font-weight: 600;
}
.navTextNumberBig {
  font-size: 2.5rem;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 10px;
}
.navTextNumber {
  font-size: 1.4rem;
  font-weight: normal;
}
hr {
  border-top: 1.9px solid #0b0334 !important;
  width: 60px;
}
hr.impressumTrenner {
  border-top: 1.9px solid #0b0334 !important;
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.carousel-item {
  transition: opacity 0.5s ease-in-out !important;
  padding-top:1em;
}
.carousel-inner {
  /* damit sticky-top möglich */
  overflow: unset !important;
}
div.sticky-top {
  /* Linie bei Sticky entfernen */
  top: -5px !important;
  width:80%;
  margin:auto;
}
@media (max-width: 768px) {
  div.sticky-top {
    top: -5% !important;
    width:100%;
    margin:auto;
  }
}

/* Navigation */

#lfdiLogo {
  width: 35px;
}
#ideLogoNav {
  width: 55px;
}
#swrLogo {
  width: 55px;
}
#navbarText {
  background-color: white;
}
#impressumButton {
  font-size: 0.6em;
}

@media (max-width: 768px) {
  .nav-item{
    font-size:2rem;
    text-align:center;
    margin-top:1.5rem;
  }
  .nav-item img{
    width: 3em;
    padding-top:1em;
  }
}

@media (min-width: 769px), (max-width: 850px) {
  .nav-item{
    font-size:1.5rem;
    text-align:center;
  }
  .nav-item img{
    width: 2em;
  }
}

/* Startseite */
body {
  background-image: url(./assets/img/personaProBg.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  /*background-attachment*/
}
.startseiteCarousel > .carousel-control-prev,
.startseiteCarousel > .carousel-control-next {
  width: 30%;
  align-items: flex-end;
}
.startseiteCarousel > a > .carousel-control-prev-icon,
.startseiteCarousel > a > .carousel-control-next-icon {
  margin-bottom: 60px;
}

.startseiteCarousel > ol.carousel-indicators li {
  border-radius: 50%;
  background-color: grey;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
  width: 8px !important;
  height: 8px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  border: 0 !important;
}
.startseiteCarousel > div.carousel-inner {
  text-align: center;
}
.startseiteCarouselDesc {
  font-size: 0.8rem;
  font-weight:500;
  font-style: italic;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .startseiteCarouselImg {
    width: 300px !important;
  }
}

/* Selbsteinschätzung */
.selbsteinschaetzugGrau {
  opacity: 0.35;
}
.selbsteinschaetzungText {
  font-size: 0.8rem;
  font-weight: 600;
  font-style: italic;
  width: 80%;
  margin: auto;
  padding-bottom: 2rem;
}
@media (max-width: 768px) {
  .selbsteinschaetzungText {
    padding-bottom: 30px;
  }
}

/* Fragen */
.row {
  padding-top:2rem;
}
.antwort {
  border: 1px solid transparent;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: 600;
}
#antwortNummern{
  display:none;
}
#einzelAntwort{
  padding-right:20%;
  padding-left:20%;
}
.antwortGrau {
  opacity: 0.45;
}
hr.antwortDivider {
  width: 100%;
}
.antwortAlignRight {
  text-align: right;
}
.frageHintergrund {
  position: absolute;
  left: calc(50% - 200px);
  opacity: 0.3;
}
@media (max-width: 768px) {
  #einzelAntwort{
    padding-right:10%;
    padding-left:10%;
  }
  .pb-1 {
    padding-top:2rem;
  }
  hr.antwortDivider {
    width: 150px !important;
  }
  .antwortAlignRight {
    text-align: left;
  }
  .frageHintergrund {
    background-image: none !important;
  }
}

/* Fragen mit Auswertung */
.antwortSelected {
  border: 1px solid black;
  padding: 5px;
}

.highlighedPersonaA {
  background-color: var(--personaA-color);
}
.highlighedPersonaB {
  background-color: var(--personaB-color);
}
.highlighedPersonaC {
  background-color: var(--personaC-color);
}
.highlighedPersonaD {
  background-color: var(--personaD-color);
}
.highlighedPersonaE {
  background-color: var(--personaE-color);
}

/* Ergebnis 1 */
.ergebnis1Result {
  display: flex;
  justify-content: center;
}
.ergebnis1Top {
  position: relative;
}
.ergebnis1ResultPercentage {
  position: absolute;
  top: -30px;
  left: 340px;
  font-size: 8rem;
  font-weight: 800;
}
.ergebnis1ResultClaim {
  margin-top: 150px;
  font-size: 0.8rem;
  font-weight: 600;
  width: 300px;
  margin-left:2em;
}
.ergebnis1ResultDesc {
  font-size: 0.8rem;
  width: 300px;
  margin-left:2em;
}
@media (max-width: 768px) {
  .ergebnis1Result {
    flex-direction: column;
  }
  .ergebnis1Top {
    position: unset;
    display: flex;
  }
  .ergebnis1Img {
    width: 300px;
  }
  .ergebnis1ResultPercentage {
    position: unset;
    left: unset;
    top: unset;
    font-size: 4rem;
    text-align: center;
  }
  .ergebnis1ResultClaim {
    position: unset;
    margin-top: unset;
    width: unset;
    padding-left:6%;
    padding-right:6%;
  }
  .ergebnis1ResultDesc {
    width: unset;
    padding-left:6%;
    padding-right:6%;
  }
}

.textcolorPersonaA {
  color: var(--personaA-color);
}
.textcolorPersonaB {
  color: var(--personaB-color);
}
.textcolorPersonaC {
  color: var(--personaC-color);
}
.textcolorPersonaD {
  color: var(--personaD-color);
}
.textcolorPersonaE {
  color: var(--personaE-color);
}

/* Ergebnis #2 */
#rowErgebnis2 {
  width:80%;
  margin:auto;
}
.ergebnis2Top {
  position: relative;
}
.ergebnis2Img {
  width: 120px;
  padding-bottom: 20px;
}
.ergebnis2ResultPercentage {
  position: absolute;
  top: -20px;
  left: 90px;
  font-size: 2rem;
  font-weight: 800;
}
.ergebnis2ResultDesc {
  font-size: 0.8rem;
  width: 210px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .ergebnis2ResultDesc {
    width: unset;
  }
}

/*Ergebnis 3*/

#rowErgebnis3 {
  width:80%;
  margin:auto;
}
#rowErgebnis3 a {
  text-decoration:underline;
}
.ergebnis3ResultDesc {
  font-size: 1em;
  width:90%;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
}
#ergebnis3img {
  width:30em;
  max-width:100%;
  margin-left: auto;
  margin-right: auto;
}
#weiteresText {
  border-top: 1px solid black;
  margin-top: 4em;
  width:80%;
  font-weight:600;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3em;
}
@media (min-width: 1200px) {
  #rowErgebnis3 {
    width:60%;
    margin:auto;
  }
  .ergebnis3ResultDesc {
    font-size: 1em;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
  }
  #weiteresText {
    width:60%;
  }
}

/* Tooltip */
.tooltip .tooltip-inner {
  background: #f8f9fa;
  color: #212529;
  border: 1px solid #ced4da;
}
.tooltip .arrow:before {
  background: white;
}
.tooltip.show {
  opacity: 1 !important;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ced4da !important;
}

/* iPhone 4 Madness */

@media (max-width: 320px) {
  .carousel-inner {
    overflow: auto !important;
  }
  #resetLinkImage {
    position: relative;
    float:left;
  }
  .navbar-toggler{
    position: relative;
    float:right;
  }
  #rowStartseite {
    width:100%;
    margin:auto;
  }
  #rowSelbsteinschaetzung{
    width:100%;
    margin:auto;
  }
  .startseiteCarouselImg {
    width: 250px !important;
  }
}

/* Desktop ab 1200 px */

@media (min-width: 1200px) {

  /* Startseite */

  #resetLinkImage {
    height:50px;
  }
  .startseiteCarouselImg {
    width: 90%;
  }
  .startseiteCarouselDesc {
    font-size:0.8rem;
  }
  #navbarText{
    font-size:1.2rem;
  }

  /* Selbsteinschaetzung */

  .selbsteinschaetzungText{
    width:60%;
  }
  #rowSelbsteinschaetzung img {
    width:50%;
  }
  #rowSelbsteinschaetzung .col-md-6 img {
    width:35%;
  }
}


/* Grosser Desktop ab 1800 px */

@media (min-width: 1800px) {

  /* Startseite */

  #resetLinkImage {
    height:60px;
  }
  .startseiteCarouselImg {
    width: 90%;
  }
  .startseiteCarouselDesc {
    font-size:1.2rem;
  }
  #navbarText{
    font-size:1.5rem;
  }
  #rowStartseite p {
    font-size:1.5rem;
    width: 80%;
  }
  #headline {
    font-size:2.2rem !important;
    width: 80%;
    padding-top:3rem;
  }
  #rowStartseite button {
    width:8rem;
    height:4rem;
    font-size: 1.5rem;
  }
  #rowStartseite .row {
    width:80%;
    padding-top:8%;
  }

  /* Selbsteinschaetzung */

  #frageSE {
    padding-top:1rem;
    padding-bottom:2rem;
    font-size:1.5rem;
  }
  .selbsteinschaetzungText{
    font-size:1rem;
    width:60%;
  }
  #rowSelbsteinschaetzung img {
    width:50%;
  }
  #rowSelbsteinschaetzung .col-md-6 img {
    width:35%;
  }

  /* Use Cases */

  #frage {
    padding-top:1rem;
    padding-bottom:2rem;
    font-size:1.5rem;
  }
  .antwort {
    font-size:1.2rem;
  }
  .frageHintergrund {
    width:30%;
    position: absolute;
    left: calc(50% - 15%);
  }

  /* Ergebnis 1 */
  .ergebnis1Result {
    display: flex;
    justify-content: center;
  }
  .ergebnis1Top {
    position: relative;
  }
  .ergebnis1ResultPercentage {
    position: absolute;
    top: -30px;
    left: 500px;
    font-size: 10rem;
    font-weight: 800;
  }
  .ergebnis1ResultClaim {
    margin-top: 180px;
    font-size: 1rem;
    font-weight: 600;
    width: 300px;
    margin-left:2em;
  }
  .ergebnis1ResultDesc {
    font-size: 1rem;
    width: 300px;
    margin-left:2em;
  }
  .ergebnis1Img {
    margin-top: 5em;
    width: 40em;
  }
  #auswertung1Head {
    padding-top:1rem;
    padding-bottom:2rem;
    font-size:1.5rem;
  }
  #auswertung2Head {
    padding-top:1rem;
    padding-bottom:2rem;
    font-size:1.5rem;
  }
  /* Ergebnis 2 */
  .ergebnis2Img {
    width: 15em;
  }
  .ergebnis2ResultPercentage {
    font-size: 4rem;
    font-weight: 800;
    position: absolute;
    top: -40px;
    left: 190px;
  }
  .ergebnis2ResultDesc {
    font-size: 1rem;
    width: 25em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:4em;
  }
}

/* Infobox */

#swrLogo2 {
  height:3em;
}

@media (max-width: 768px){
#ideLogo {
  height:6em;
  margin-bottom: 3.5em;
  margin-top: 2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#lfdiLogo2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height:5em;
  margin-bottom: 4em;
}
#bawueLogo {
  height:6em;
  margin-bottom: 2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4em;
}
#swrLogo2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height:3em;
  margin-bottom: 2em;
}
}