// Override default variables before the import

/* https://github.com/twbs/bootstrap/blob/v4.6.0/scss/_variables.scss */

$font-family-sans-serif: "Montserrat", sans-serif !default;

$light: white;

$carousel-control-color: black;
$carousel-indicator-active-bg: green;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$spacers: (
  6: (
    $spacer * 4,
  ),
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
